/** **************************** Import Packages ****************************** */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";

/** ********************************* formik ************************************* */
import { useFormik } from "formik";
import * as yup from "yup";

/** ********************************* assets ************************************* */
import Logo from "../../assets/images/sns-logo.png";

// *********************************** Redux *******************************/

/** ********************************* CSS ************************************* */
import "./register.css";
import LoginInput from "../../components/forms-controls/Input-elements/login-input";
import { addErrorIcon, alphabetValidate, numberValidationMx } from "../../utils/common";
import ButtonComponent from "../../components/button";
import { PiStudentFill } from "react-icons/pi";
import {
  FacultyLoginAPI,
  FacultyRegistrationAPI,
  StudentLoginAPI,
  StudentRegistrationAPI,
} from "../../api/create";
import { MdKeyboardArrowLeft } from "react-icons/md";
import Select from "../../components/forms-controls/Input-elements/select";
import { DepartmentDetails } from "../../constants/input-options";

const Signup = (props) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [LoginUser, setLoginUser] = useState("Student");

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      email: "",
      phone: "",
      department: "",
      password: "",
      confirmPassword: "",
    },

    validationSchema: yup.object({
      name: yup
        .string()
        .required("Name is required")
        .max(100, "Name should exceeds more than 100 characters"),
      phone: yup
        .string()
        .matches(/^[0-9]+$/, "Only numbers are allowed")
        .min(10, "Phone number should be 10 digits")
        .max(10, "Phone number should be 10 digits")
        .required("Phone is required"),
      department: yup.string().required("Department is required"),
      email: yup
        .string()
        .required("Email is required")
        .strict()
        .matches(
          /^[a-zA-Z](([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          "Enter the valid email"
        )
        .email("Enter valid email")
        .max(150, "Email should exceeds more than 150 characters"),
      password: yup
        .string()
        .required("Password is required")
        .min(8, "Password must be at least 8 characters")
        .test(
          "has-special-character",
          "Password must contain at least one special character.",
          (value) => /[!@#$%^&*]/.test(value)
        )
        .test(
          "has-capital-letter",
          "Password must contain at least one uppercase letter",
          (value) => /[A-Z]/.test(value)
        )
        .test(
          "has-digit",
          "Password must contain at least one digit",
          (value) => /[0-9]/.test(value)
        )
        .test(
          "has-lowercase-letter",
          "Password must contain at least one lowercase letter",
          (value) => /[a-z]/.test(value)
        ),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
    }),
    onSubmit: async (values) => {
      const postData = {
        name: values?.name,
        email: values?.email.toLowerCase(),
        phone: values?.phone,
        department: values?.department,
        password: values?.password,
      };
      setLoader(true);
      const handleLoginUser = () => {
        return LoginUser === "Student"
          ? StudentRegistrationAPI(postData)
          : FacultyRegistrationAPI(postData);
      };
      await handleLoginUser().then((res) => {
        if (res?.success === true) {
          history.push("/login");
          setLoader(false);
          toast.success("Registered Successfully");
          formik.resetForm();
        } else if (res?.message) {
          setLoader(false);
          toast.error(res?.message);
        } else {
          setLoader(false);
          toast.error(res?.message);
        }
      });
    },
  });

  return (
    <div className="login-main  div-center register">
      <div className="container">
        <div className="row justify-content-end">
          <div className=" col-lg-5 d-flex justify-content-center align-items-center">
            <div>
              <img src={Logo} width={150} height={150} alt="" />
              <div className="d-flex align-items-center pb-2">
                <div className="icon-badge">
                  <PiStudentFill size={14} color="white" />
                </div>
                <div>
                  <div className="login-sub-heading px-3">
                    Student Performance Tracker
                  </div>
                </div>
              </div>
              <div className="login-page-contents">
                <h2 className="fw-semibold my-2">Explore Your New You</h2>
                <p className="login-summary pb-3 pt-2">
                  Track your performance progress, Lorem ipsum dolor sit amet
                  consectetur adipisicing elit. Eius ipsa, maiores quia quam
                  cupiditate voluptates quasi suscipit inventore quisquam quas
                  doloremque labore.
                </p>
              </div>
              <div>
                <ButtonComponent
                  backgroundColor="#08AB93"
                  color="white"
                  fontWeight={600}
                  hoverBorder="1px solid #08AB93"
                >
                  View rewards
                </ButtonComponent>
              </div>
            </div>
          </div>
          <div className=" col-lg-6 d-flex justify-content-center align-items-center">
            <div className=" col-lg-12  ">
              <div
                style={{ borderRadius: "15px" }}
                className="overflow-hidden mt-3 bg-light mx-3"
              >
                <div className="row justify-content-center">
                  <div className="col-8  py-4 px-4 login-form  mt-3">
                    <h5 className="text-dark fw-bold text-center  fs-4">
                      Welcome to SNS Groups
                    </h5>
                    <h1
                      className="mb-4 mt-3"
                      style={{
                        color: "#052621",
                        fontWeight: "600",
                        fontSize: "24px",
                        textAlign: "center",
                      }}
                    >
                      Sign Up
                    </h1>
                    <div
                      style={{
                        backgroundColor: "#f0f0f0",
                        borderRadius: "8px",
                      }}
                      className={`d-flex justify-content-center text-center login_role`}
                    >
                      <div
                        className={`student ${
                          LoginUser === "Student" && "student_active"
                        }`}
                        onClick={() => setLoginUser("Student")}
                      >
                        Student
                      </div>
                      <div
                        className={`faculty ${
                          LoginUser === "Faculty" && "faculty_active"
                        }`}
                        onClick={() => setLoginUser("Faculty")}
                      >
                        Faculty
                      </div>
                    </div>
                  </div>
                  <form
                    onSubmit={(event) => {
                      formik.handleSubmit(event);
                    }}
                    className="row mt-3 px-4"
                  >
                    <div className="col-12 col-md-6 px-3">
                      <LoginInput
                        required
                        labelName="Name"
                        type="text"
                        placeholder="Name"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        onKeyPress={alphabetValidate}
                      />
                      <div className="error-space">
                        {formik.errors.name && formik.touched.name ? (
                          <div className="error-txt ps-2">
                            {addErrorIcon(formik.errors.name)}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-6 px-3">
                      <LoginInput
                        required
                        labelName="Email"
                        type="email"
                        placeholder="Email"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <div className="error-space">
                        {formik.errors.email && formik.touched.email ? (
                          <div className="error-txt ps-2">
                            {addErrorIcon(formik.errors.email)}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-6 px-3">
                      <LoginInput
                        required
                        labelName="Phone Number"
                        type="tel"
                        placeholder="Phone Number"
                        name="phone"
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        onKeyPress={(e) => numberValidationMx(e, 10)}
                      />
                      <div className="error-space">
                        {formik.errors.phone && formik.touched.phone ? (
                          <div className="error-txt ps-2">
                            {addErrorIcon(formik.errors.phone)}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-6 px-3">
                      <div className="mt-2">
                        <label
                          htmlFor=""
                          className="ps-2 my-1"
                          style={{ fontSize: 14, fontWeight: 600 }}
                        >
                          Department <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          name="department"
                          className=" department-select"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.department}
                        >
                          <option value="">Select</option>
                          {DepartmentDetails &&
                            DepartmentDetails.length > 0 && (
                              <>
                                {DepartmentDetails.map((department) => (
                                  <option
                                    key={department.label}
                                    value={department.label}
                                  >
                                    {department.label}
                                  </option>
                                ))}
                              </>
                            )}
                        </select>
                        <div className="error-space">
                          {formik.errors.department &&
                          formik.touched.department ? (
                            <div className="error-txt ps-2">
                              {addErrorIcon(formik.errors.department)}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 px-3">
                      <LoginInput
                        labelName="Password"
                        required
                        type={passwordShown ? "text" : "password"}
                        placeholder="Password"
                        name="password"
                        value={formik.values.password}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        icon={true}
                        iconValue={
                          <i
                            role="button"
                            className={`fa password-eye  ${
                              passwordShown ? "fa-eye" : "fa-eye-slash"
                            } password-icon`}
                            onClick={() => setPasswordShown(!passwordShown)}
                          />
                        }
                      />
                      <div className="error-space">
                        {formik.errors.password && formik.touched.password ? (
                          <div className="error-txt ps-2">
                            {addErrorIcon(formik.errors.password)}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-6 px-3">
                      <LoginInput
                        required
                        labelName="Confirm Password"
                        type={confirmPasswordShown ? "text" : "password"}
                        placeholder="Confirm Password"
                        name="confirmPassword"
                        onBlur={formik.handleBlur}
                        value={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                        icon={true}
                        iconValue={
                          <i
                            role="button"
                            className={`fa password-eye  ${
                              confirmPasswordShown ? "fa-eye" : "fa-eye-slash"
                            } password-icon`}
                            onClick={() =>
                              setConfirmPasswordShown(!confirmPasswordShown)
                            }
                          />
                        }
                      />
                      <div className="error-space">
                        {formik.errors.confirmPassword &&
                        formik.touched.confirmPassword ? (
                          <div className="error-txt ps-2">
                            {addErrorIcon(formik.errors.confirmPassword)}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 px-3">
                      <div className="text-center">
                        <button type="submit" className="login-button mt-4">
                          {loader ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm ml-2"
                                role="status"
                                aria-hidden="false"
                              />
                              <span className="sr-only">Loading...</span>
                            </>
                          ) : (
                            "Sign Up"
                          )}
                        </button>
                      </div>

                      <div className="text-center my-3 text-muted fs-6 fw-bold">
                        or
                      </div>
                      <div className="text-center my-3 text-muted fs-6 ">
                        <Link to="/login" className="back-login">
                          Already have an account
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
