/** **************************** Import Libs ****************************** */
import { combineReducers } from "redux";

/** **************************** Import Reducers ****************************** */
import {
  sideBarReducer,
  NavBarReducer,
  profileDropDownReducer,
  loginReducer,
} from "./reducers";

const rootReducer = combineReducers({
  SideBar: sideBarReducer,
  profileDropdown: profileDropDownReducer,
  NavbarReduce: NavBarReducer,
  loginReducer : loginReducer,
});

export default rootReducer;
