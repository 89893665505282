import React from "react";
import "../form-control.css";

function Select(props) {
  const {
    onChange,
    onKeyPress,
    style,
    name,
    onBlur,
    className,
    value,
    type,
    placeholder,
    labelName,
    labelClass,
    disabled,
    id,
    options,
    Required,
    optional,
    defaultSelect,
    errorInput,
    formInputC,
  } = props;

  return (
    <div
      className={` mt-3 ${formInputC} ${
        errorInput ? "error-input" : null
      } ${disabled === true ? "disabled-field" : "form-input"} `}
    >
      <label className={`${labelClass} mb-1`}>
        {labelName}
        {Required ? (
          <span className="text-danger required-input">*</span>
        ) : optional ? (
          <span className="optional-text">(Optional)</span>
        ) : null}
      </label>
      <div className="icon-relative">
        <select
          id={id}
          name={name}
          type={type}
          className={className}
          onChange={onChange}
          onKeyPress={onKeyPress}
          value={value}
          placeholder={placeholder}
          onBlur={onBlur}
          disabled={disabled}
        >
          {defaultSelect ? <option value="">Select</option> : null}
          {options}
        </select>
      </div>
    </div>
  );
}

export default Select;

// "use client";
// import React, { useEffect, useState } from "react";

// export const DropDown = (props: any) => {
//   const {
//     selectedValue,
//     setSelectedValue,
//     options,
//     divStyle,
//     buttonStyle,
//     optionCardstyle,
//     optionStyle,
//     open,
//     setOpen,
//     tab,
//   } = props;
//   useEffect(() => {
//     setSelectedValue(options[0]);
//   }, [tab]);
//   return (
//     <div
//       className={`relative ${divStyle}`}
//       onClick={(e: any) => e.stopPropagation()}
//     >
//       <button
//         id="dropdownHoverButton"
//         data-dropdown-toggle="dropdownHover"
//         data-dropdown-trigger="hover"
//         className={`w-full text-white font-medium rounded-[12px] border-[3px] border-[#92a9c54d] text-sm px-5 py-2.5 text-center inline-flex items-center justify-between ${buttonStyle} `}
//         type="button"
//         onClick={(e: any) => {
//           setOpen(!open);
//         }}
//       >
//         {selectedValue}
//         <svg
//           className={`w-2.5 h-2.5 ms-3 duration-500 ${
//             open ? "rotate-180" : ""
//           }`}
//           aria-hidden="true"
//           xmlns="http://www.w3.org/2000/svg"
//           fill="none"
//           viewBox="0 0 10 6"
//         >
//           <path
//             stroke="currentColor"
//             stroke-linecap="round"
//             stroke-linejoin="round"
//             stroke-width="2"
//             d="m1 1 4 4 4-4"
//           />
//         </svg>
//       </button>

//       <div
//         id="dropdownHover"
//         className={`z-10 w-full ${
//           open ? "block" : "hidden"
//         } absolute divide-y  rounded-lg shadow w-44 dropdown_content border-2 border-[#92a9c54d] top-[110%] overflow-hidden`}
//         style={{ ...optionCardstyle }}
//       >
//         <ul
//           className=" text-sm"
//           aria-labelledby="dropdownHoverButton"
//         >
//           {options?.length > 0 &&
//             options?.map((items: any, index: number) => (
//               <li
//                 key={index}
//                 className={`block px-4 py-[16px] text-white ${optionStyle}`}
//                 onClick={(e: any) => {
//                   setSelectedValue(items);
//                   setOpen(!open);
//                 }}
//               >
//                 {items}
//               </li>
//             ))}
//         </ul>
//       </div>
//     </div>
//   );
// };
