/** **************************** Import Packages ****************************** */
import React from "react";
import { ThemeProvider } from "styled-components";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";

/** **************************** Import Images ****************************** */
import { ApiLoader } from "./components/loader/loader";

/** **************************** Import Constants ****************************** */
import { DarkTheme } from "./constants/themes";

/** **************************** Import Pages ****************************** */
import Login from "./container/login";
import "./App.css";
import Signup from "./container/registration";

const App = () => {
  const Layout = React.lazy(() => import("./container/layout"));
  return (
    <ThemeProvider theme={DarkTheme}>
      <Router>
        <React.Suspense
          fallback={
            <div className="py-5 my-5">
              <ApiLoader />
            </div>
          }
        >
          <Switch>
            <Route exact path="/login" render={() => <Login />} />
            <Route exact path="/sign-up" render={() => <Signup />} />
            <Route
              path="/"
              name="Dashboard"
              render={(props) =>
                localStorage.getItem("loggedUser") ? (
                  <Layout {...props} />
                ) : (
                  (window.location.href = "/login")
                )
              }
            />{" "}
            <Route path="*" exact={true} />{" "}
          </Switch>{" "}
        </React.Suspense>{" "}
      </Router>{" "}
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "",
          duration: 3000,
          style: {
            background: "#fff",
            color: "#000",
          },

          // Default options for specific types
          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
          error: {
            style: {
              background: "#fbe8e9",
              color: "#000",
            },
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
    </ThemeProvider>
  );
};
export default App;
