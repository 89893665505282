export const roles = [
    {
        value: "",
        label: "Select",
    },
    {
        value: "1",
        label: "Super Admin",
    },
    {
        value: "2",
        label: "Executive",
    },
    {
        value: "3",
        label: "Marketing",
    },
];


export const active = [
    {
        value: true,
        label: "Active",
    },
    {
        value: false,
        label: "Suspend",
    },
];

export const MenuAccessNew = [
    {
        MenuAccess: [
            { name: "dashboard", value: false, route: "/home", label: "Dashboard" },
            { name: "customers", value: false, route: "/customers", label: "Customers" },
            {
                name: "settings", label: "Settings", subMenu: [{
                    name: "subscriptions",
                    route: "/settings/subscriptions",
                    value: false,
                    label: "subscriptions"
                },
                {
                    name: "tickets",
                    route: "/settings/tickets",
                    value: false,
                    label: "Tickets"
                }]
            },
            { name: "enquiries", value: false, route: "/enquiries", label: "Enquiries" },
            { name: "basic-details", value: false, route: "/basic-details", label: "Basic Details" },
            { name: "user-management", value: false, route: "/user-management", label: "Users Management" },
            { name: "roles-settings", value: false, route: "/role-settings", label: "Role Settings" }
        ]
    }
]

export const MenuAccess = [
    {
        MenuAccess: [
            { name: "dashboard", value: false, label: "Dashboard" },
            { name: "customers", value: false, label: "Customers" },
            {
                name: "settings", label: "Settings", value: false, subMenu: [{
                    name: "subscriptions",
                    value: false,
                    label: "Subscriptions"
                },
                {
                    name: "dropdown",
                    value: false,
                    label: "Dropdown"
                },
                {
                    name: "cms",
                    value: false,
                    label: "CMS"
                },
                ]
            },
            {
                name: "inquiries", value: false, label: "Inquiries", subMenu: [
                    {
                        name: "contact-us",
                        value: false,
                        label: "Contact us"
                    },
                    {
                        name: "connected-data",
                        value: false,
                        label: "Connect Data"
                    },
                    {
                        name: "demo-bookings",
                        value: false,
                        label: "Demo Bookings"
                    },
                ]
            },
            { name: "tickets", value: false, label: "Tickets" },
            { name: "basic-details", value: false, route: "/basic-details", label: "Basic Details" },
            { name: "user-management", value: false, route: "/user-management", label: "User Management" },
            { name: "roles-settings", value: false, route: "/role-settings", label: "Roles Settings" }
        ]
    }
]

export const selectOption =[
    {
        teamMembers: "Kani",
    },
    {
        teamMembers: "Naveen",
    },
    {
        teamMembers: "Ponaswin",
    },
    {
        teamMembers: "Surya",
    },
    {
        teamMembers: "Ram",
    },
    {
        teamMembers: "Tamil",
    },
    {
        teamMembers: "Sneha",
    },
    {
        teamMembers: "Deepi",
    },
    {
        teamMembers: "Suganthi",
    },
]

export const DepartmentDetails = [
    {label: "B.E.Aerospace", value: "1"},
    {label: "B.E.Agri", value: "2"},
    {label: "B.E.Auto", value: "3"}, 
    {label: "B.E.BME", value: "4"},
    {label: "B.E.CSE", value: "5"},
    {label: "B.E.ECE", value: "6"},
    {label: "B.E.EEE", value: "7"},
    {label: "B.E.MCT", value: "8"},
    {label: "B.E.Mech", value: "9"},
    {label: "B.Tech AI/ML", value: "10"},
    {label: "B.Tech Food", value: "10"},
    {label: "B.Tech IT", value: "10"},
]

export const Providers = [
    {label: "PrepInsta", value: "1"}
]