import { HiOutlineExclamationCircle } from "react-icons/hi";
import "../App.css";

const ErrorIcon = () => <HiOutlineExclamationCircle className="error-icon" />;

export const addErrorIcon = (message) => (
    <div className="d-flex">
        <ErrorIcon />
        {message}
    </div>
);

export const alphabetValidate = (e) => {
  const keyCode = e.keyCode || e.which;
  const keyValue = String.fromCharCode(keyCode);
  const isAlphabet = /^[A-Za-z.]$/.test(keyValue);

  if (
    e.target.value.length === 0 &&
    keyValue === " " &&
    e.target.value[0] !== " "
  ) {
    e.preventDefault();
  } else if (
    isAlphabet ||
    (keyValue === " " && !e.target.value.endsWith(" "))
  ) {
    return;
  } else {
    e.preventDefault();
  }
};

export const numericValidate = (e) => {
  const keyCode = e.keyCode || e.which;
  const keyValue = String.fromCharCode(keyCode);
  const isAlphabet = /^[0-9]$/.test(keyValue);

  if (
    e.target.value.length === 0 &&
    keyValue === " " &&
    e.target.value[0] !== " "
  ) {
    e.preventDefault();
  } else if (
    isAlphabet ||
    (keyValue === " " && !e.target.value.endsWith(" "))
  ) {
    return;
  } else {
    e.preventDefault();
  }
};

export const numericValidateLmt = (e, value) => {
  const keyCode = e.keyCode || e.which;
  const keyValue = String.fromCharCode(keyCode);
  const isAlphanumeric = /^[0-9]$/.test(keyValue);

  if (
    e.target.value.length < value &&
    (e.target.value.length === 0 ||
      ((keyValue === " " || isAlphanumeric) && e.target.value[0] !== " "))
  ) {
    return;
  } else if (
    isAlphanumeric &&
    keyValue === " " &&
    !e.target.value.endsWith(" ")
  ) {
    return;
  } else {
    e.preventDefault();
  }
};

export const numberValidationMx = (e, value) => {
  const keyCode = e.keyCode || e.which;
  const keyValue = String.fromCharCode(keyCode);
  const numericRegex = /^\d+$/;

  if (
    e.target.value.length < value &&
    (e.target.value.length === 0 ||
      ((keyValue === " " || numericRegex.test(keyValue)) && e.target.value[0] !== ""))
  ) {
    return;
  } else if (
    numericRegex.test(keyValue) &&
    keyValue === " " &&
    !e.target.value.endsWith(" ")
  ) {
    return;
  } else {
    e.preventDefault();
  }
};


export const spaceValidate = (e) => {
  const keyCode = e.keyCode || e.which;
  const keyValue = String.fromCharCode(keyCode);
  const inputValue = e.target.value + keyValue;

  if (inputValue.startsWith(" ")) {
    e.preventDefault();
  }
};

export const alphanumericValidate = (e, value) => {
  const keyCode = e.keyCode || e.which;
  const keyValue = String.fromCharCode(keyCode);
  const isAlphanumeric = /^[A-Za-z0-9]$/.test(keyValue);

  if (
    e.target.value.length < value &&
    (e.target.value.length === 0 ||
      ((keyValue === " " || isAlphanumeric) && e.target.value[0] !== " "))
  ) {
    return;
  } else if (
    isAlphanumeric &&
    keyValue === " " &&
    !e.target.value.endsWith(" ")
  ) {
    return;
  } else {
    e.preventDefault();
  }
};

export const NoSpaceValidate = (e) => {
  const keyCode = e.keyCode || e.which;
  const keyValue = String.fromCharCode(keyCode);

  // Check if the key is a space or a capital letter
  if (keyValue === " " || (keyValue >= "A" && keyValue <= "Z")) {
    e.preventDefault();
  }
};

export const formatDate = (timestamp) => {
  if (!timestamp) return "Date not found!";

  const currentDate = new Date();
  const emailDate = new Date(timestamp);
  const diffInMs = currentDate - emailDate;
  const diffInMinutes = diffInMs / (1000 * 60);

  if (diffInMinutes < 1) {
    return "Less than a minute ago";
  } else if (diffInMinutes < 60) {
    return `${Math.floor(diffInMinutes)} min${
      Math.floor(diffInMinutes) > 1 ? "s" : ""
    } ago | ${emailDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    })}`;
  } else if (diffInMinutes < 60 * 24) {
    return `${Math.floor(diffInMinutes / 60)} hour${
      Math.floor(diffInMinutes / 60) > 1 ? "s" : ""
    } ago | ${emailDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    })}`;
  } else if (diffInMinutes < 60 * 24 * 7) {
    return `${Math.floor(diffInMinutes / (60 * 24))} day${
      Math.floor(diffInMinutes / (60 * 24)) > 1 ? "s" : ""
    } ago | ${emailDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    })}`;
  } else if (diffInMinutes < 60 * 24 * 30) {
    return `${Math.floor(diffInMinutes / (60 * 24 * 7))} week${
      Math.floor(diffInMinutes / (60 * 24 * 7)) > 1 ? "s" : ""
    } ago | ${emailDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    })}`;
  } else {
    return emailDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  }
};

export const handleRole = () => {
  const role = JSON.parse(localStorage.getItem("loggedUser"))?.role;
  if (role === "role" || role === "student") {
    return true;
  } else if (role === "faculty") {
    return false;
  }
};
