import React from "react";
import "../form-control.css";

function LoginInput(props) {
  const {
    onChange,
    onKeyPress,
    style,
    name,
    onBlur,
    className,
    value,
    type,
    placeholder,
    disabled,
    icon,
    inputIconClass,
    iconValue,
    id,
    autoComplete,
    formInputC,
    errorInput,
    labelName,
    required,
  } = props;

  return (
    <div
      className={`login-form-input mt-2 ${formInputC} ${
        errorInput ? "error-input" : null
      }`}
    >
      {labelName && (
        <label
          htmlFor={id}
          className="ps-2 my-1"
          style={{ fontSize: 14, fontWeight: 600 }}
        >
          {labelName} {required && <span style={{ color: "red" }}>*</span>}
        </label>
      )}
      <div className="icon-relative">
        <input
          id={id}
          name={name}
          type={type}
          className={className}
          onChange={onChange}
          onKeyPress={onKeyPress}
          value={value}
          placeholder={placeholder}
          style={style}
          onBlur={onBlur}
          disabled={disabled}
          autoComplete={autoComplete}
        />
        {icon === true ? (
          <div className={`icon-absolute ${inputIconClass}`}>{iconValue}</div>
        ) : null}
      </div>
    </div>
  );
}

export default LoginInput;
