const config = {
  production: {
    url: "",
    apiUrl: "https://10n8c9f374.execute-api.ap-south-1.amazonaws.com/dev/",
  },
  develop: {
    url: "",
    apiUrl: "https://live-backend.sns5pillars.in/api/v1/",
    bucketName: "okr-automation-dev",
    region: "ap-south-1",
  },
  local: {
    url: "http://localhost:5000/",
    apiUrl: "http://localhost:5030/v1/",
    bucketName: "okr-automation-dev",
    region: "ap-south-1",
  },
};

export const environment = "develop";
// https://okr-automation-dev.s3.ap-south-1.amazonaws.com/documents/kanimozhi_1721034525135.pdf

const hostConfig = {
  WEB_URL: config[environment].url,
  IMAGE_URL: `https://${config[environment].bucketName}.s3.ap-south-1.amazonaws.com`,
  API_URL: config[environment].apiUrl,
  S3_BUCKET: `${config[environment].bucketName}`,
  REGION: `${config[environment].region}`,
};

export { hostConfig };
