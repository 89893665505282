/** *******************Import styled components************************ */
import styled from "styled-components";

export const Button = styled.button`
  width: ${(props) => (props.width ? props.width : "auto")};
  height: ${(props) => (props.height ? props.height : "36px")};
  color: ${(props) => (props.color ? props.color : "#000")};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "#08AB93"};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "12px"};
  padding: ${(props) => (props.padding ? props.padding : "5px 25px")};
  margin: ${(props) => (props.margin ? props.margin : "auto")};
  border: ${(props) => (props.border ? props.border : "none")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "normal")};
  position: relative;
  font-size: 14px;
   &:hover {
    background-color:${(props) => (props.noHover ? props.backgroundColor : props.color ? props.color : "#fff")};
    color: ${(props) => (props.noHover ? props.color : props.backgroundColor ? props.backgroundColor : "#000")};
    border: ${(props) =>
      props.hoverBorder ? props.hoverBorder : props.border ? props.border : "none"};
  }
`;
export const Span = styled.span`
 
`;
