/** ****************************** Import libs *********************************** */

const URL_CONSTANTS = {
  users: "users",
  refreshToken: "refresh-tokens",
  faculty: "faculty/registration",
  student: "student/registration",
  postTokensData: "users",
  login: "admin_login",
  studentLogin: "auth/student/login",
  facultyLogin: "auth/faculty/login",
  pillars: "student/pillars/details",
  pillarsView: "student/pillars",
  certificate: "student/okr/submission",
  certificateVerification: "student/okr/certificate/verify",
  facultyCertificate: "faculty/student-status",
  facultyMembers: "faculty/student-details",
  studentDetails: "student",
  groupMembers: "student/okr/groups"
};

export { URL_CONSTANTS };
