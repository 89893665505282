/** **************************** Import Packages ****************************** */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";

/** ********************************* formik ************************************* */
import { useFormik } from "formik";
import * as yup from "yup";

/** ********************************* assets ************************************* */
import Logo from "../../assets/images/sns-logo.png";

// *********************************** Redux *******************************/
import { LoginUser } from "../../redux/actions";

/** ********************************* CSS ************************************* */
import "./login.css";
import LoginInput from "../../components/forms-controls/Input-elements/login-input";
import { addErrorIcon } from "../../utils/common";
import ButtonComponent from "../../components/button";
import { PiStudentFill } from "react-icons/pi";
import { FacultyLoginAPI, StudentLoginAPI } from "../../api/create";

const Login = (props) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [LoginUser, setLoginUser] = useState("Student");

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },

    validationSchema: yup.object({
      email: yup
        .string()
        .required("Email is required")
        .strict()
        .matches(
          /^[a-zA-Z](([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          "Enter the valid email"
        )
        .email("Enter valid email"),
      password: yup
        .string()
        .required("Password is required")
        .min(5, "Minimum 5 characters is required"),
    }),
    onSubmit: async (values) => {
      setLoader(true);
      const handleLoginUser = () => {
        values.email = values.email.toLowerCase();
        return LoginUser === "Student"
          ? StudentLoginAPI(values)
          : FacultyLoginAPI(values);
      };
      await handleLoginUser().then((res) => {
        if (res?.user) {
          const accessToken = res?.tokens?.access?.token;
          const RefreshToken = res?.tokens?.refresh?.token;
          localStorage.setItem("refreshToken", RefreshToken);
          localStorage.setItem("token", accessToken);
          localStorage.setItem("loggedUser", JSON.stringify(res?.user));
          history.push("/home");
          setLoader(false);
          toast.success("Logged in Successfully");
          formik.resetForm();
        } else if (res?.message) {
          setLoader(false);
          toast.error(res?.message);
        } else {
          setLoader(false);
          toast.error(res?.message);
        }
      });
    },
  });

  return (
    <div className="login-main  div-center">
      <div className="container">
        <div className="row justify-content-end">
          <div className=" col-lg-5 d-flex justify-content-center align-items-center">
            <div>
              <img src={Logo} width={150} height={150} alt="" />
              <div className="d-flex align-items-center pb-2">
                <div className="icon-badge">
                  <PiStudentFill size={14} color="white" />
                </div>
                <div>
                  <div className="login-sub-heading px-3">
                    Student Performance Tracker
                  </div>
                </div>
              </div>
              <div className="login-page-contents">
                <h2 className="fw-semibold my-2">Explore Your New You</h2>
                <p className="login-summary pb-3 pt-2">
                  Track your performance progress, Lorem ipsum dolor sit amet
                  consectetur adipisicing elit. Eius ipsa, maiores quia quam
                  cupiditate voluptates quasi suscipit inventore quisquam quas
                  doloremque labore.
                </p>
              </div>
              <div>
                <ButtonComponent
                  backgroundColor="#08AB93"
                  color="white"
                  fontWeight={600}
                  hoverBorder="1px solid #08AB93"
                >
                  View rewards
                </ButtonComponent>
              </div>
            </div>
          </div>
          <div className=" col-lg-6 d-flex justify-content-center align-items-center">
            <div className=" col-lg-10  ">
              <div
                style={{ borderRadius: "15px" }}
                className="overflow-hidden bg-light mt-3"
              >
                <div className="row justify-content-center">
                  <div className="col-9  py-4 px-4 login-form mt-3 ">
                    <h5 className="text-dark fw-bold text-center fs-4">
                      Welcome to SNS Groups
                    </h5>
                    <h1
                      className="mb-4 mt-3"
                      style={{
                        color: "#052621",
                        fontWeight: "600",
                        fontSize: "24px",
                        textAlign: "center",
                      }}
                    >
                      Log In
                    </h1>
                    <div
                      style={{
                        backgroundColor: "#f0f0f0",
                        borderRadius: "8px",
                      }}
                      className={`d-flex justify-content-center text-center login_role`}
                    >
                      <div
                        className={`student ${
                          LoginUser === "Student" && "student_active"
                        }`}
                        onClick={() => setLoginUser("Student")}
                      >
                        Student
                      </div>
                      <div
                        className={`faculty ${
                          LoginUser === "Faculty" && "faculty_active"
                        }`}
                        onClick={() => setLoginUser("Faculty")}
                      >
                        Faculty
                      </div>
                    </div>
                  </div>
                  <form
                    onSubmit={(event) => {
                      formik.handleSubmit(event);
                    }}
                    className="row mt-3 px-5"
                  >
                    <div>
                      <LoginInput
                        labelName="Email"
                        required
                        type="email"
                        placeholder="Email"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                      />
                      <div className="error-space">
                        {formik.errors.email && formik.touched.email ? (
                          <div className="error-txt ps-2">
                            {addErrorIcon(formik.errors.email)}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div>
                      <LoginInput
                        labelName="Password"
                        required
                        type={passwordShown ? "text" : "password"}
                        placeholder="Password"
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        icon={true}
                        iconValue={
                          <i
                            role="button"
                            className={`fa password-eye  ${
                              passwordShown ? "fa-eye" : "fa-eye-slash"
                            } password-icon`}
                            onClick={() => setPasswordShown(!passwordShown)}
                          />
                        }
                      />
                      <div className="error-space">
                        {formik.errors.password && formik.touched.password ? (
                          <div className="error-txt ps-2">
                            {addErrorIcon(formik.errors.password)}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12">
                      <button type="submit" className="login-button mt-3">
                        {loader ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm ml-2"
                              role="status"
                              aria-hidden="false"
                            />
                            <span className="sr-only">Loading...</span>
                          </>
                        ) : (
                          "Login"
                        )}
                      </button>
                      <div className="text-center my-3 text-muted fs-6 fw-bold">
                        or
                      </div>
                      <div className="text-center my-3 text-muted fs-6 ">
                        Register as a{" "}
                        <Link to="/sign-up" className="reroute">
                          new user
                        </Link>
                        ?
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
