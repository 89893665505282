import React from "react";
import { Button, Span } from "./style";
const ButtonComponent = (props) => {
  const {
    width,
    height,
    backgroundColor,
    style,
    className,
    children,
    border,
    color,
    type,
    onClick,
    padding,
    icon,
    margin,
    disabled,
    fontWeight,
    noHover,
    ariaLabel,
    hoverBorder,
    ref
  } = props;
  return (
    <Button
      margin={margin}
      width={width}
      height={height}
      backgroundColor={backgroundColor}
      style={style}
      className={className}
      border={border}
      color={color}
      type={type}
      padding={padding}
      onClick={onClick}
      disabled={disabled}
      fontWeight={fontWeight}
      noHover={noHover}
      aria-label={ariaLabel}
      hoverBorder={hoverBorder}
      ref={ref}
    >
      {icon ? <Span>{icon ? icon : null}&nbsp;&nbsp;</Span> : null}
      {children}
    </Button>
  );
};

export default ButtonComponent;
