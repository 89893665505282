/** ****************************** Import libs *********************************** */
import { postDataApi } from "./actions";
import { URL_CONSTANTS } from "./urls";

export const postLoginRequestData = (params) =>
  postDataApi(URL_CONSTANTS.login, params);
export const postLogoutRequestData = (params) =>
  postDataApi(URL_CONSTANTS.logout, params);
export const StudentLoginAPI = (params) =>
  postDataApi(URL_CONSTANTS.studentLogin, params);
export const FacultyLoginAPI = (params) =>
  postDataApi(URL_CONSTANTS.facultyLogin, params);
export const CertificateForm = (params) =>
  postDataApi(URL_CONSTANTS.certificate, params);
export const FacultyRegistrationAPI = (params) =>
  postDataApi(URL_CONSTANTS.faculty, params);
export const StudentRegistrationAPI = (params) =>
  postDataApi(URL_CONSTANTS.student, params);
export const CertificateVerification = (params) =>
  postDataApi(URL_CONSTANTS.certificateVerification, params);
